
import { computed, defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import ChatsMessageDetailsModal from '@/components/modals/chats/ChatsMessageDetailsModal.vue'

export default defineComponent({
  components: { ChatsMessageDetailsModal, TmButton },
  props: {
    title: {
      type: String,
      default: 'Message preview',
    },
    content: {
      type: String,
    },
  },
  setup(props) {
    const items = computed(() => [
      { label: 'From', value: '(204) 762-8765' },
      { label: 'To', value: '(207) 555-0119' },
      { label: 'Message content', value: props.content },
      { label: 'Total characters', value: '32 characters' },
      { label: 'Message parts', value: '1 part' },
      { label: 'Cost of this message', value: '$0.038' },
    ])
    return {
      items,
    }
  },
})
